import styled from "styled-components";
import { Container as Wrapper } from "../../styles/UI";

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 0;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.bp.veryBigDesktop}) {
    overflow: ${(p) => (p.$clip ? "hidden" : "visible")};
  }
`;

export const Container = styled(Wrapper)`
  .cta {
    position: relative;
    z-index: 10;

    h1 {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -0.01em;
      margin-bottom: 24px;
      line-height: 1.25;
    }

    p {
      font-weight: 350;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .illustration {
    z-index: 1;
    margin-top: 0px;
    position: relative;

    .bg-logo {
      position: absolute;
      z-index: 0;
      height: 800px;
      right: -180px;
      top: -240px;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        left: 60px;
        top: -240px;
        height: 980px;
      }
    }
  }

  .hero-illustration {
    /* filter: drop-shadow(14px 24px 80px rgba(0, 0, 0, 0.65)); */
    z-index: 10;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    max-height: 820px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    align-items: center;
  }
`;

export const SobMedida = styled.section`
  padding: 100px 0;
  text-align: center;
  background: white;

  h3 {
    font-size: 24px;
    line-height: 32px;

    max-width: 320px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.01em;
    color: #00458e;
    margin: 0 auto;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-width: 770px;
      font-size: 35px;
      line-height: 42px;
    }
  }

  p {
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: ${(p) => p.theme.textLightGray};
    margin: 16px 0 32px;

    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      max-width: 300px;
      margin: 32px auto;
    }
  }
`;

export const Faq = styled.section`
  padding: 124px 0;
  background-color: ${(p) => p.theme.bgGray};

  h3 {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 56px;
  }
`;
